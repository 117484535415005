<template>
<v-container>
  <v-fade-transition>
    <v-card v-show="screen0">
      <v-card-title>Welcome - Tell us about your startup</v-card-title>
      <v-card-text>
        <v-text-field label="Name of Startup" outlined v-model="startup.name"></v-text-field>
        <v-textarea label="Pitch your startup" outlined v-model="startup.description"></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="next">Next</v-btn>
      </v-card-actions>
    </v-card>
  </v-fade-transition>
  <v-fade-transition>
    <v-card v-show="screen1">
      <v-card-title>Welcome - {{startup.name}}</v-card-title>
      <v-card-text>
        <v-select label="Industry" :items="industries" multiple outlined></v-select>
        <v-select label="Startup Stage" :items="startupStages" outlined></v-select>
        <v-select label="Startup Size" :items="startupSizes" outlined></v-select>
        <v-select label="Impact startup SDGs" :items="sdgs" multiple outlined></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="back">Back</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="next">Next</v-btn>
      </v-card-actions>
    </v-card>
  </v-fade-transition>
  <v-fade-transition>
    <v-card v-show="screen2">
      <v-card-title>Tell us about yourself</v-card-title>
      <v-card-text>
        <v-textarea label="About me" outlined v-model="user.description"></v-textarea>
        <v-file-input
            accept="image/*"
            label="Profile photo"
            outlined
            prepend-icon="mdi-camera"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="back">Back</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="next">Next</v-btn>
      </v-card-actions>
    </v-card>
  </v-fade-transition>
  <v-fade-transition>
    <v-card v-show="screen3">
      <v-card-title>Screen 4</v-card-title>
      <v-card-actions>
        <v-btn outlined @click="back">Back</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="next">Next</v-btn>
      </v-card-actions>
    </v-card>
  </v-fade-transition>
</v-container>
</template>

<script>
export default {
  name: "Welcome",
  data: () => ({
    screen0: true,
    screen1: false,
    screen2: false,
    screen3: false,
    screen4: false,

    startup: {
      name: "",
      description: "",
    },

    user: {
      description: "",
    },

    industries: [
      "Advertising",
      "Agriculture",
      "Blockchain",
      "Consumer Goods",
      "Education",
      "Energy & Greentech",
      "Fashion & Living",
      "Fintech",
      "Food & Beverage",
      "Gaming",
      "Healthcare & Life Science",
      "Hospitality, Hosting",
      "IT & Software",
      "IoT",
      "Jobs & Recruitment",
      "Legal",
      "Manufacturing",
      "Maritime & Transport",
      "Marketplace & eCommerce",
      "Media & Entertainment",
      "Music & Audio",
      "Retail",
      "Robotics",
      "SaaS",
      "Sales & Marketing",
      "Science & Engineering",
      "Service",
      "Sports",
      "Telecommunications",
      "Travel & Tourism",
    ],
    startupStages: [
      "Idea",
      "Product or prototype",
      "Go to market",
      "Growth and expansion",
    ],
    startupSizes: [
      "1-10",
      "11-50",
      "51-100",
      "101-200",
      "200+",
    ],
    sdgs: [
      "1. No poverty",
      "2. Zero hunger",
      "3. Good health and well-being",
      "4. Quality education",
      "5. Gender equality",
      "6. Clean water and sanitation",
      "7. Affordable and clean energy",
      "8. Decent work and economic growth",
      "9. Industry, innovation and infrastructure",
      "10. Reduced inequalities",
      "11. Sustainable cities and communities",
      "12. Responsible consumption and production",
      "13. Climate action",
      "14. Life below water",
      "15. Life on land",
      "16. Peace, justice and strong institutions",
      "17. Partnerships for the goals",
    ],
  }),
  methods: {
    next() {
      if(this.screen0) {
        this.screen0 = false;
        setTimeout(()=>{this.screen1 = true}, 300)
      }
      if(this.screen1) {
        this.screen1 = false;
        setTimeout(()=>{this.screen2 = true}, 300)
      }
      if(this.screen2) {
        this.screen2 = false;
        setTimeout(()=>{this.screen3 = true}, 300)
      }
      if(this.screen3) {
        this.screen3 = false;
        setTimeout(()=>{this.screen0 = true}, 300)
      }
    },
    back() {
      if(this.screen1) {
        this.screen1 = false;
        setTimeout(()=>{this.screen0 = true}, 300)
      }
      if(this.screen2) {
        this.screen2 = false;
        setTimeout(()=>{this.screen1 = true}, 300)
      }
      if(this.screen3) {
        this.screen3 = false;
        setTimeout(()=>{this.screen2 = true}, 300)
      }
      if(this.screen4) {
        this.screen4 = false;
        setTimeout(()=>{this.screen3 = true}, 300)
      }
    },
  },
}
</script>

<style scoped>

</style>