<template>
  <v-container>
    <div class="text-center">
      <img alt="Vue logo" src="/img/impact-hub-logo.svg">
    </div>
    <v-card elevation="0">
      <v-card-title class="mb-5"><h1>Welcome</h1></v-card-title>
      <v-card-text>
        This is just a demo.
      </v-card-text>
      <v-card-text>
        <v-select label="Industry" :items="industries" multiple outlined></v-select>
        <v-select label="Startup Stage" :items="startupStages" outlined></v-select>
        <v-select label="Startup Size" :items="startupSizes" outlined></v-select>
        <v-select label="Impact startup SDGs" :items="sdgs" multiple outlined></v-select>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data: () => ({
    industries: [
      "Advertising",
      "Agriculture",
      "Blockchain",
      "Consumer Goods",
      "Education",
      "Energy & Greentech",
      "Fashion & Living",
      "Fintech",
      "Food & Beverage",
      "Gaming",
      "Healthcare & Life Science",
      "Hospitality, Hosting",
      "IT & Software",
      "IoT",
      "Jobs & Recruitment",
      "Legal",
      "Manufacturing",
      "Maritime & Transport",
      "Marketplace & eCommerce",
      "Media & Entertainment",
      "Music & Audio",
      "Retail",
      "Robotics",
      "SaaS",
      "Sales & Marketing",
      "Science & Engineering",
      "Service",
      "Sports",
      "Telecommunications",
      "Travel & Tourism",
    ],
    startupStages: [
      "Idea",
      "Product or prototype",
      "Go to market",
      "Growth and expansion",
    ],
    startupSizes: [
      "1-10",
      "11-50",
      "51-100",
      "101-200",
      "200+",
    ],
    sdgs: [
      "1. No poverty",
      "2. Zero hunger",
      "3. Good health and well-being",
      "4. Quality education",
      "5. Gender equality",
      "6. Clean water and sanitation",
      "7. Affordable and clean energy",
      "8. Decent work and economic growth",
      "9. Industry, innovation and infrastructure",
      "10. Reduced inequalities",
      "11. Sustainable cities and communities",
      "12. Responsible consumption and production",
      "13. Climate action",
      "14. Life below water",
      "15. Life on land",
      "16. Peace, justice and strong institutions",
      "17. Partnerships for the goals",
    ],
    diagnosticTypes: [
        "Begin",
        "Mid term",
        "Final"
    ],
    diagnosticType: "Mid term",
    assessmentTypes: [
        "0 - no clue",
        "1 - some clue",
        "2 - aware",
        "3 - clear",
        "4 - convincing (on paper)",
        "5 - clear assumptions",
        "6 - testing",
        "7 - tested (works)",
        "8 - entry",
        "9 - running",
        "10 - scaling",
    ],
    startup: "Wastics",
    ratingImpact: null,
    ratingValueProposition: "",
    focusAreas: [
      { header: 'Value Proposition' },
      { name: 'Product / Solution', group: 'Value Proposition' },
      { name: 'Impact Model', group: 'Value Proposition' },
      { name: 'Target Market / Customers', group: 'Value Proposition' },
      { name: 'USP / Competition', group: 'Value Proposition' },
      { divider: true },
      { header: 'Strategy' },
      { name: 'Business / Revenue Model ', group: 'Strategy' },
      { name: 'Go-to-Market Strategy', group: 'Strategy' },
      { name: 'Impact Model / Theory of Change', group: 'Strategy' },
      { divider: true },
      { header: 'Operations' },
      { name: 'Team & Advisors', group: 'Operations' },
      { name: 'Financials (3 year plan, funding need, use of funds)', group: 'Operations' },
      { name: 'Investment proposition', group: 'Operations' },
      { name: 'Timeline', group: 'Operations' },
    ],
    focusArea: [],
    milestones: [],
    milestone: {
      name: "",
      priority: 0,
    },
    defaultMilestone: {
      name: "",
      priority: 0,
    },
  }),
  methods: {
    remove (item) {
      const index = this.focusArea.indexOf(item.name)
      if (index >= 0) this.focusArea.splice(index, 1)
    },
    addMilestone() {
      this.milestones.push(this.milestone);
      this.milestone = Object.assign({}, this.defaultMilestone);
    },
    removeMilestone(name) {
      const index = this.milestones.indexOf(name)
      if (index >= 0) this.milestones.splice(index, 1)
    },
  },
}
</script>
