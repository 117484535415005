<template>
  <v-container>
    <v-card>
      <v-img
          height="250"
          src="https://source.unsplash.com/UB6jto6FgCA/800x300"
      ></v-img>
      <v-card-title style="margin-top: -90px;">
        <v-row>
          <v-col cols="12">
            <v-avatar color="primary" size="100">
              <img
                  :src="startupData.logoUrl"
                  :alt="startupData.name"
              >
            </v-avatar>
          </v-col>
          <v-col cols="12">
            {{startupData.name}}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <p>{{startupData.shortDescription}}</p>
        <div>
          <v-chip-group>
            <v-chip>Crypto</v-chip>
            <v-chip>Finance</v-chip>
          </v-chip-group>
        </div>
        <div class="mt-3">
          <h2>Beschreibung</h2>
          <p style="white-space: pre-wrap;">{{startupData.longDescription}}</p>
        </div>
        <v-row class="mt-5">
          <v-col cols="12" md="6">
            <div>
              <h2>Team</h2>
              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="(team,i) in startupData.team" :key="i">
                    <v-list-item-avatar>
                      <img
                          :src="team.avatar"
                      >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{team.firstname}} {{team.lastname}}</v-list-item-title>
                      <v-list-item-subtitle>{{team.role}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <h2>Contact</h2>
              <p class="mt-2">
                <a v-if="startupData.contact.phone" :href="`tel:+${startupData.contact.phone}`"><v-icon small>mdi-phone</v-icon> {{startupData.contact.phone}}</a><br>
                <a v-if="startupData.contact.email" :href="`mailto:${startupData.contact.email}`"><v-icon small>mdi-email</v-icon> {{startupData.contact.email}}</a><br>
                <a v-if="startupData.contact.web" :href="startupData.contact.web" target="_blank"><v-icon small>mdi-web</v-icon> {{startupData.contact.web}}</a><br>
              </p>
            </div>
          </v-col>
        </v-row>
        <div v-if="startupData.lookingFor">
          <h2>We are looking for</h2>
          <p>{{startupData.lookingFor}}</p>
        </div>
      </v-card-text>
    </v-card>
    <!--<pre>{{$route.params.id}}</pre>-->
  </v-container>
</template>

<script>
export default {
  name: "ProfileStartup",
  data: () => ({
    startupData: {
      name: "Bitcoin Defenders",
      logoUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png",
      founded: "2022-03-05",
      shortDescription: "We help people using crypto currency in their daily life.",
      longDescription: "",
      mainInnovation: "",
      type: [
          "Product",
          "Service",
          "Business Model"
      ],
      industries: [],
      tags: [],
      internalProgram: "",
      externalPrograms: [],
      team: [
        {
          firstname: "John",
          lastname: "Smith",
          role: "CEO",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          education: "",
          experience: "",
          passion: "",
          socialLinks: [
            {
              linkedin: "",
              instagram: "",
              web: "",
            }
          ],
        },
        {
          firstname: "Samantha",
          lastname: "Jackson",
          role: "CTO",
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          education: "",
          experience: "",
          passion: "",
          socialLinks: [
            {
              linkedin: "",
              xing: "",
              instagram: "",
              web: "",
            }
          ],
        },
      ],
      contact: {
        contactPerson: "",
        email: "founders@bitdefenders.com",
        phone: "+4369012345577",
        web: "https://bitdefenders.com",
        linkedin: "",
        instagram: "",
      },
      lookingFor: "",
    },
  }),
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>