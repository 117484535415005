import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#822B26',
        //secondary: '#424242',
        secondary: '#DF9E53',
        //accent: '#82B1FF',
        accent: '#8EB8E5',
        error: '#FF5252',
        //info: '#2196F3',
        info: '#5F7470',
        //success: '#4CAF50',
        success: '#03CEA4',
        warning: '#FFC107',
      },
      dark: {
        primary: "#00bcd4",
        secondary: "#673ab7",
        accent: "#9c27b0",
        error: "#f44336",
        warning: "#ffeb3b",
        info: "#607d8b",
        success: "#009688"
      },
    },
  },
});
