<template>
<v-container>
  <v-card elevation="0">
    <v-card-title>Startups</v-card-title>
    <v-card-text>
      <v-btn to="/startups/bit-defenders">Bit Defenders</v-btn>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
export default {
  name: "Startups"
}
</script>

<style scoped>

</style>