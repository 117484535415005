<template>
  <v-container>
    <v-card elevation="0">
      <v-card-title>Experts</v-card-title>
      <v-card-text>
        <v-btn to="/experts/hasibeder">Benedikt Hasibeder</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Experts"
}
</script>

<style scoped>

</style>