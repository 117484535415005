<template>
  <v-container>
    <div class="text-center">
      <img alt="Vue logo" src="/img/impact-hub-logo.svg">
    </div>
    <v-card elevation="0">
      <v-card-title class="mb-5"><h1>Diagnostics</h1></v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field label="Startup" v-model="startup" outlined></v-text-field>
          <v-select label="Type" v-model="diagnosticType" outlined :items="diagnosticTypes"></v-select>
        </v-form>
      </v-card-text>
      <v-card-text>
        <h2 class="mb-5">Impact Model</h2>
        <v-select
            label="Assessment"
            :items="assessmentTypes"
            outlined
        ></v-select>
        <v-textarea
            label="Comments"
            outlined
        ></v-textarea>
        <!-- <v-rating
            v-model="ratingImpact"
            length="11"
            background-color="purple lighten-3"
            color="purple"
            large
        >
          <template v-slot:item="props">
            <v-icon
                large
                :color="props.isFilled ? 'purple darken-4' : ''"
                v-text="`mdi-numeric-${props.index}-box`"
                @click="props.click"
            ></v-icon>
          </template>
        </v-rating> -->
        <h2 class="mb-5">Value Proposition</h2>
        <v-select
            label="Assessment"
            :items="assessmentTypes"
            outlined
        ></v-select>
        <v-textarea
            label="Comments"
            outlined
        ></v-textarea>
        <h2 class="mb-5">Competition Analysis</h2>
        <v-select
            label="Assessment"
            :items="assessmentTypes"
            outlined
        ></v-select>
        <v-textarea
            label="Comments"
            outlined
        ></v-textarea>
        <h2 class="mb-5">Customers & Target Market</h2>
        <v-select
            label="Assessment"
            :items="assessmentTypes"
            outlined
        ></v-select>
        <v-textarea
            label="Comments"
            outlined
        ></v-textarea>
        <h2 class="mb-5">Revenue Model</h2>
        <v-select
            label="Assessment"
            :items="assessmentTypes"
            outlined
        ></v-select>
        <v-textarea
            label="Comments"
            outlined
        ></v-textarea>
        <h2 class="mb-5">Go-To-Market Strategy</h2>
        <v-select
            label="Assessment"
            :items="assessmentTypes"
            outlined
        ></v-select>
        <v-textarea
            label="Comments"
            outlined
        ></v-textarea>
        <h2 class="mb-5">Financial Plan</h2>
        <v-select
            label="Assessment"
            :items="assessmentTypes"
            outlined
        ></v-select>
        <v-textarea
            label="Comments"
            outlined
        ></v-textarea>
        <h2 class="mb-5">Investment Case</h2>
        <v-select
            label="Assessment"
            :items="assessmentTypes"
            outlined
        ></v-select>
        <v-textarea
            label="Comments"
            outlined
        ></v-textarea>
        <h2 class="mb-5">Timeline</h2>
        <v-select
            label="Assessment"
            :items="assessmentTypes"
            outlined
        ></v-select>
        <v-textarea
            label="Comments"
            outlined
        ></v-textarea>
        <h2 class="mb-5">Team & Advisors</h2>
        <v-select
            label="Assessment"
            :items="assessmentTypes"
            outlined
        ></v-select>
        <v-textarea
            label="Comments"
            outlined
        ></v-textarea>
        <h2 class="mb-5">Focus Areas</h2>
        <v-autocomplete
            outlined
            label="Focus Area A"
            :items="focusAreas"
            v-model="focusArea"
            item-value="name"
            item-text="name"
        >
          <!--<template v-slot:selection="data">
            <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="remove(data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template> -->
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
        <v-textarea
            label="Details"
            outlined
        ></v-textarea>
      </v-card-text>
      <v-card-text>
        <h2 class="mb-5">Suggested Milestones</h2>
        <v-row v-for="(milestone0, i) in milestones" :key="i">
          <v-col cols="9">
            <v-text-field label="Name" outlined v-model="milestone0.name"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn icon outlined class="mt-3" color="red" @click="removeMilestone(milestone0)"><v-icon>mdi-minus</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9">
            <v-text-field label="Name" v-model="milestone.name" outlined></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn icon outlined class="mt-3" @click="addMilestone"><v-icon>mdi-plus</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-textarea
            label="Notes"
            outlined
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined>Cancel</v-btn>
        <v-btn color="primary" outlined>Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "DiagnosticsSheet",
  data: () => ({
    diagnosticTypes: [
      "Begin",
      "Mid term",
      "Final"
    ],
    diagnosticType: "Mid term",
    assessmentTypes: [
      "0 - no clue",
      "1 - some clue",
      "2 - aware",
      "3 - clear",
      "4 - convincing (on paper)",
      "5 - clear assumptions",
      "6 - testing",
      "7 - tested (works)",
      "8 - entry",
      "9 - running",
      "10 - scaling",
    ],
    startup: "Wastics",
    ratingImpact: null,
    ratingValueProposition: "",
    focusAreas: [
      { header: 'Value Proposition' },
      { name: 'Product / Solution', group: 'Value Proposition' },
      { name: 'Impact Model', group: 'Value Proposition' },
      { name: 'Target Market / Customers', group: 'Value Proposition' },
      { name: 'USP / Competition', group: 'Value Proposition' },
      { divider: true },
      { header: 'Strategy' },
      { name: 'Business / Revenue Model ', group: 'Strategy' },
      { name: 'Go-to-Market Strategy', group: 'Strategy' },
      { name: 'Impact Model / Theory of Change', group: 'Strategy' },
      { divider: true },
      { header: 'Operations' },
      { name: 'Team & Advisors', group: 'Operations' },
      { name: 'Financials (3 year plan, funding need, use of funds)', group: 'Operations' },
      { name: 'Investment proposition', group: 'Operations' },
      { name: 'Timeline', group: 'Operations' },
    ],
    focusArea: [],
    milestones: [],
    milestone: {
      name: "",
      priority: 0,
    },
    defaultMilestone: {
      name: "",
      priority: 0,
    },
  }),
  methods: {
    remove (item) {
      const index = this.focusArea.indexOf(item.name)
      if (index >= 0) this.focusArea.splice(index, 1)
    },
    addMilestone() {
      this.milestones.push(this.milestone);
      this.milestone = Object.assign({}, this.defaultMilestone);
    },
    removeMilestone(name) {
      const index = this.milestones.indexOf(name)
      if (index >= 0) this.milestones.splice(index, 1)
    },
  },
}
</script>

<style scoped>

</style>