<template>
<v-container>
  <v-row>
    <v-col cols="6">
      <v-card>
        <v-card-title>Milestones</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col>1</v-col>
                  <v-col>Marketing</v-col>
                  <v-col class="text-right"><v-chip :outlined="isDark">Open</v-chip></v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col>2</v-col>
                  <v-col>Business Model</v-col>
                  <v-col class="text-right"><v-chip :outlined="isDark" color="yellow">In Progress</v-chip></v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col>3</v-col>
                  <v-col>Idea Validation</v-col>
                  <v-col class="text-right"><v-chip :outlined="isDark" color="green">Done</v-chip></v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card>
        <v-card-title>Expert Hours</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col><b>Available</b></v-col>
                  <v-col class="text-right"><b>7:30</b></v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col>Benedikt</v-col>
                  <v-col class="text-right">1:30</v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col>René</v-col>
                  <v-col class="text-right">1:00</v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card>
        <v-card-title>Upcoming Events</v-card-title>
        <v-card-text></v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card>
        <v-card-title>Open Tasks</v-card-title>
        <v-card-text></v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  name: "Dashboard",
  computed: {
    isDark() {
      if(this.$vuetify.theme.dark) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped>

</style>