<template>
  <v-container>
    <v-card>
      <v-img
          height="250"
          :src="expertData.headerImage"
      ></v-img>
      <v-card-title style="margin-top: -90px;">
        <v-row>
          <v-col cols="12">
            <v-avatar color="transparent" size="100">
              <img
                  :src="expertData.avatar"
                  :alt="`${expertData.firstname} ${expertData.lastname}`"
              >
            </v-avatar>
          </v-col>
          <v-col cols="12">
            {{expertData.firstname}} {{expertData.lastname}} <v-icon class="pb-1" small color="primary">mdi-check-decagram</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-5">
          <v-col cols="12" md="8">
            <p>{{expertData.shortDescription}}</p>
            <div>
              <v-chip-group>
                <v-chip>Digital Marketing</v-chip>
                <v-chip>Sales</v-chip>
                <v-chip>Innovation Consulting</v-chip>
              </v-chip-group>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div>
              <h2>Contact</h2>
              <p class="mt-2">
                <a v-if="expertData.phone" :href="`tel:+${expertData.phone}`"><v-icon small>mdi-phone</v-icon> {{expertData.phone}}</a><br>
                <a v-if="expertData.email" :href="`mailto:${expertData.email}`"><v-icon small>mdi-email</v-icon> {{expertData.email}}</a><br>
                <a v-if="expertData.socialLinks.web" :href="expertData.socialLinks.web" target="_blank"><v-icon small>mdi-web</v-icon> {{expertData.socialLinks.web}}</a><br>
              </p>
            </div>
          </v-col>
        </v-row>
        <div class="mt-3">
          <h2>Beschreibung</h2>
          <p style="white-space: pre-wrap;">{{expertData.longDescription}}</p>
        </div>
        <div v-if="startupData.lookingFor">
          <h2>We are looking for</h2>
          <p>{{startupData.lookingFor}}</p>
        </div>
      </v-card-text>
    </v-card>
    <!--<pre>{{$route.params.id}}</pre>-->
  </v-container>
</template>

<script>
export default {
  name: "ProfileExpert",
  data: () => ({
    expertData: {
      avatar: "https://pointofnew.at/wp-content/uploads/2022/02/WhatsApp-Image-2022-02-10-at-22.28-copy-1-e1644532879780-750x768.jpg",
      headerImage: "https://pointofnew.at/wp-content/uploads/2018/05/header_flowerpot-600x514.png",
      firstname: "Benedikt",
      lastname: "Hasibeder",
      email: "benedikt@pointofnew.at",
      phone: "",
      socialLinks: {
        linkedin: "https://www.linkedin.com/in/benedikt-hasibeder/",
        instagram: "",
        web: "https://pointofnew.at/",
      },
      shortDescription: "Design and market your products and services in the best way possible!",
      longDescription: "Following my passion for Innovation Management, I wanted to not only consult, but also to help realising my client’s business ideas. Therefore I partnered up with experts in design and marketing to develop and optimise the product-to-market process. Individual coaching and training completes my offer to ensure the best outcome for my clients.\n" +
          "\n" +
          "Work Experience: Global Process Manager for Innovation and Technology | Senior Consultant for post merger projects | Founder and Co-Founder of Innovation, Design and Digital Marketing Agencies | Innovation Consultant | Startup Trainer | Business Coach\n" +
          "\n" +
          "Education: Innovation and Product Management | Industrial Design | Quality Manager | Senior Process Manager | Senior Risk Manager | Project Manager",

      industries: [],
      tags: [],
    },
    startupData: {
      name: "Bitcoin Defenders",
      logoUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/1200px-Bitcoin.svg.png",
      founded: "2022-03-05",
      shortDescription: "Design and market your products and services in the best way possible!",
      longDescription: "Following my passion for Innovation Management, I wanted to not only consult, but also to help realising my client’s business ideas. Therefore I partnered up with experts in design and marketing to develop and optimise the product-to-market process. Individual coaching and training completes my offer to ensure the best outcome for my clients.\n" +
          "\n" +
          "Work Experience: Global Process Manager for Innovation and Technology | Senior Consultant for post merger projects | Founder and Co-Founder of Innovation, Design and Digital Marketing Agencies | Innovation Consultant | Startup Trainer | Business Coach\n" +
          "\n" +
          "Education: Innovation and Product Management | Industrial Design | Quality Manager | Senior Process Manager | Senior Risk Manager | Project Manager",
      mainInnovation: "",
      type: [
        "Product",
        "Service",
        "Business Model"
      ],
      industries: [],
      tags: [],
      internalProgram: "",
      externalPrograms: [],
      team: [
        {
          firstname: "John",
          lastname: "Smith",
          role: "CEO",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          education: "",
          experience: "",
          passion: "",
          socialLinks: [
            {
              linkedin: "",
              instagram: "",
              web: "",
            }
          ],
        },
        {
          firstname: "Samantha",
          lastname: "Jackson",
          role: "CTO",
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          education: "",
          experience: "",
          passion: "",
          socialLinks: [
            {
              linkedin: "",
              xing: "",
              instagram: "",
              web: "",
            }
          ],
        },
      ],
      contact: {
        contactPerson: "",
        email: "founders@bitdefenders.com",
        phone: "+4369012345577",
        web: "https://bitdefenders.com",
        linkedin: "",
        instagram: "",
      },
      lookingFor: "",
    },
  }),
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>