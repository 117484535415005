<template>
  <v-app :style="{background: appBarColor}">
    <v-navigation-drawer app v-model="navigation">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img src="/img/default-avatar.png"></v-img>
          </v-list-item-avatar>
          <v-list-item-content v-if="loggedIn">
            <v-list-item-title class="title">
              {{user.firstname}} {{user.lastname}}
            </v-list-item-title>
            <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content v-else>
            <v-list-item-title class="title">
              Create account
            </v-list-item-title>
            <v-list-item-subtitle>Just register</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-group
            color="primary"
        >
          <v-list-item to="/">
            <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/welcome">
            <v-list-item-icon><v-icon>mdi-hand-wave-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Welcome</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/diagnostics">
            <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Diagnostics</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/applications">
            <v-list-item-icon><v-icon>mdi-file-document-multiple</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Applications</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/experts">
            <v-list-item-icon><v-icon>mdi-account-details</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Experts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/startups">
            <v-list-item-icon><v-icon>mdi-account-details</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Startups</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-list>
        <v-list-item-group>
          <v-list-item to="/jobs">
            <v-list-item-icon><v-icon>mdi-briefcase-search</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Jobs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list>
        <v-list-item-group color="secondary">
          <v-list-item to="/about">
            <v-list-item-icon><v-icon>mdi-information</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>About</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/settings">
            <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div class="pa-3">
        <v-tooltip v-if="!$vuetify.theme.dark" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="info" small fab @click="darkMode">
              <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
            </v-btn>
          </template>
          <span>Dark Mode On</span>
        </v-tooltip>

        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="info" small fab @click="darkMode">
              <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
            </v-btn>
          </template>
          <span>Dark Mode Off</span>
        </v-tooltip>
      </div>
    </v-navigation-drawer>
    <v-app-bar
      app
      :color="appBarColor"
      elevate-on-scroll
    >
      <v-app-bar-nav-icon @click.stop="navigation = !navigation"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div class="d-flex align-center" v-if="!isDark">
        <v-img
            alt="Impact Hub"
            class="shrink mr-2"
            contain
            :src="logo"
            transition="scale-transition"
            width="40"
        />
        <div class="c-logo__link__text" v-if="!isDark">Vienna</div>
      </div>
      <div v-if="isDark">
        <v-img
            alt="Skale Digital"
            class="shrink mr-2"
            contain
            :src="logo"
            transition="scale-transition"
            width="100"
        />
      </div>
    </v-app-bar>

    <v-main>
      <v-slide-x-transition mode="out-in">
        <router-view/>
      </v-slide-x-transition>
    </v-main>
  </v-app>
</template>

<style scoped>
.c-logo__link__text {
  font-size: 30px;
  font-weight: bolder;
  color: #404040;
}
</style>

<script>

export default {
  name: 'App',
  data: () => ({
    navigation: false,
    loggedIn: false,
    user: {
      firstname: "Maxi",
      lastname: "Mustafra",
      email: "maxi@impacthub.net"
    },
  }),
  computed: {
    appBarColor() {
      if(this.$vuetify.theme.dark) {
        return "black"
      } else {
        return "white"
      }
    },
    logo() {
      if(this.$vuetify.theme.dark) {
        return "/img/skale.png"
      } else {
        return "/img/impact-hub-logo.svg"
      }
    },
    isDark() {
      if(this.$vuetify.theme.dark) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  },
};
</script>
