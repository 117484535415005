import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Startups from "@/views/Startups";
import Experts from "@/views/Experts";
import Settings from "@/views/Settings";
import ProfileStartup from "@/views/ProfileStartup";
import JobBoard from "@/views/JobBoard";
import ProfileExpert from "@/views/ProfileExpert";
import DiagnosticsSheet from "@/views/DiagnosticsSheet";
import Welcome from "@/views/Welcome.vue";
import Dashboard from "@/views/Dashboard.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/diagnostics',
    name: 'Diagnostics',
    component: DiagnosticsSheet
  },
  {
    path: '/startups',
    name: 'Startups',
    component: Startups
  },
  {
    path: '/startups/:id',
    name: 'StartupProfiles',
    component: ProfileStartup
  },
  {
    path: '/experts',
    name: 'Experts',
    component: Experts
  },
  {
    path: '/experts/:id',
    name: 'ExpertProfiles',
    component: ProfileExpert
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: JobBoard
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
